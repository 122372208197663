import React, { Component } from "react";

import MyTheme from "../common/Theme";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AOS from "aos"; // Animate on scroll
import { MuiThemeProvider } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

class Accessibility extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    AOS.init();
  };

  render() {
    return (
      <div className="fluid-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Richmond Post-Acute Care Accessibility Statement</title>
          <meta
            name="description"
            content="Richmond Post-Acute Care strives to ensure that its services are accessible to people with disabilities."
          />
          <link
            rel="canonical"
            href="https://richmondpostacute.com/accessibility"
          />
        </Helmet>
        <div
          className="container Accessibility"
          data-aos="fade-down"
          data-aos-once="true"
        >
          <MuiThemeProvider theme={MyTheme}>
            <div className="statementContainer">
              <Paper className={"Test"} elevation={1}>
                <h3 className="text-center py-3">
                  Richmond Post-Acute Care Accessibility Statement
                </h3>
                <Typography component="h3">General:</Typography>
                <Typography component="p">
                  Richmond Post-Acute Care strives to ensure that its services
                  are accessible to people with disabilities. Richmond
                  Post-Acute Care has invested a significant amount of resources
                  to help ensure that its website is made easier to use and more
                  accessible for people with disabilities, with the strong
                  belief that every person has the right to live with dignity,
                  equality, comfort and independence.
                </Typography>
                <Typography component="p">
                  Accessibility on <Link to="/">Richmondpostacute.com</Link>{" "}
                  makes available the UserWay Website Accessibility Widget that
                  is powered by a dedicated accessibility server. The software
                  allows our website to improve its compliance with the Web
                  Content Accessibility Guidelines (WCAG 2.1).
                </Typography>
                <Typography component="h3">
                  Enabling the Accessibility Menu:
                </Typography>
                <Typography component="p">
                  The websites accessibility menu can be enabled by clicking the
                  accessibility menu icon{" "}
                  <i
                    className="fas fa-universal-access fa-2x"
                    style={{ color: "#06a7d3" }}
                  ></i>{" "}
                  that appears on the far right corner of the page. After
                  triggering the accessibility menu, please wait a moment for
                  the accessibility menu to load in its entirety.
                </Typography>
                <Typography component="h3">Disclaimer:</Typography>
                <Typography component="p">
                  Richmond Post-Acute Care continues its efforts to constantly
                  improve the accessibility of its site and services in the
                  belief that it is our collective moral obligation to allow
                  seamless, accessible and unhindered use also for those of us
                  with disabilities.
                </Typography>
                <Typography component="p">
                  Despite our efforts to make all pages and content on{" "}
                  <Link to="/">Richmondpostacute.com</Link> fully accessible,
                  some content may not have yet been fully adapted to the
                  strictest accessibility standards. This may be a result of not
                  having found or identified the most appropriate technological
                  solution.
                </Typography>
                <Typography component="h3">We're here for you:</Typography>
                <Typography component="p">
                  If you are experiencing difficulty with any content on{" "}
                  <Link to="/">Richmondpostacute.com</Link> or require
                  assistance with any part of our site, please contact us any
                  time as we're available 24 hours a day/7 days a week and we
                  will be happy to assist.
                </Typography>
                <Typography component="h3">Contact Us:</Typography>
                <Typography component="p">
                  If you wish to report an accessibility issue that you're
                  experiencing with our website, have any questions or need
                  assistance, please submit an inquiry to us{" "}
                  <Link to="/contactUs">here</Link>. In addition, you can also{" "}
                  <a href="mailto:info@richmondpostacute.com">email us</a> or
                  give us a <a href="tel:5104266969">call</a> for any additional
                  questions you might have for accessibility on our website.
                </Typography>
              </Paper>
            </div>
          </MuiThemeProvider>
        </div>
      </div>
    );
  }
}

export default Accessibility;
