// 404 page
import React from "react";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AOS from "aos"; // Animate on scroll

class Nomatch extends React.Component {
  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div className="noMatchcontainer">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Richmond Post-Acute Care 404 Page Not Found</title>
          <meta
            name="description"
            content="Richmond Post-Acute Care Page requested was not found."
          />
        </Helmet>
        <div className="fluidContainer">
          <div className="bgimage">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-md-6 col-md-auto">
                  <div className="bgContent">
                    <div className="overlay">
                      <div data-aos="fade-down" data-aos-once="true">
                        <h1>404 - PAGE NOT FOUND</h1>
                        <p>
                          Sorry but the page you are looking for does not exist,
                          have been removed. Name changed or is temporarily
                          unavailable.
                        </p>
                        <Link to="/">
                          <button className="btn btn-primary">
                            BACK TO HOMEPAGE
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Nomatch;
