import serviceBase from "./serviceBase";

const formService = {
  emailsubscribeAuth: (data) =>
    serviceBase.post("/api/emailsubscribeAuth", data),
  calltoactionAuth: (data) => serviceBase.post("/api/calltoactionAuth", data),
  voipcallAuth: (data) => serviceBase.post("/api/voipcallAuth", data),
  communityAuth: (data) => serviceBase.post("/api/communityAuth", data),
  contactAuth: (data) => serviceBase.post("/api/contactAuth", data),
  feedbackAuth: (data) => serviceBase.post("/api/feedbackAuth", data),
  careerAuth: (data) => serviceBase.post("/api/careerAuth", data),
};

export default formService;
