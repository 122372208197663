import React from "react";

import AOS from "aos"; // Animate on scroll
import { Helmet } from "react-helmet";

import UserReviews from "./UserReviews";

const Testimonials = () => {
  AOS.init({
    duration: 2000,
  });
  AOS.refresh();

  return (
    <div className="testimonailsContainer">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Richmond Post-Acute Care Testimonials</title>
        <meta
          name="description"
          content="Richmond Post-Acute Care has reviews from how people feel about the facility."
        />
        <link
          rel="canonical"
          href="https://www.richmondpostacute.com/testimonials"
        />
      </Helmet>

      <div className="testimonials">
        <div className="container">
          <h1 className="py-3 col-md-12 col-xs-12 text-md-left text-center">
            Testimonials
          </h1>
          <div className="nursingcareTreatment">
            <div className="container">
              <UserReviews />
            </div>
          </div>

          <div className="reviews">
            <h1 className="py-3 col-md-12 col-xs-12 text-md-left text-center">
              How is your experience?
            </h1>

            <h2 className="pb-3 col-md-12 col-xs-12 text-md-left text-center">
              Leave us a review!
            </h2>
            <div className="row justify-content-center">
              <div className="col-md-3 shadow-sm p-3 m-2">
                <a
                  href="https://www.yelp.com/biz/richmond-post-acute-care-richmond"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card m-3 text-center">
                    <h4 className="card-title pt-3">
                      <strong>Yelp</strong>
                    </h4>
                    <div className="card-body">
                      <i className="fab fa-yelp fa-4x"></i>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-3 shadow-sm p-3 m-2">
                <a
                  href="https://www.facebook.com/Richmond-Post-Acute-Care-113023083404859/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card m-3 text-center">
                    <h4 className="card-title pt-3">
                      <strong>Facebook</strong>
                    </h4>
                    <div className="card-body">
                      <i className="fab fa-facebook-square fa-4x"></i>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-3 shadow-sm p-3 m-2">
                <a
                  href="https://www.google.com/search?q=richmond+post+acute+care&ei=MqEGZJ-lCrKy0PEPip-z2As&ved=0ahUKEwifhp_J4sj9AhUyGTQIHYrPDLsQ4dUDCBA&uact=5&oq=richmond+post+acute+care&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzILCC4QrwEQxwEQgAQyBggAEBYQHjoECAAQQzoFCAAQkQI6CwguEIAEELEDEIMBOgUIABCABDoFCC4QgAQ6EQguEIAEELEDEIMBEMcBENEDOhEILhCABBCxAxDHARDRAxDUAjoICAAQgAQQsQM6BggAEAoQQzoKCC4QxwEQ0QMQQzoOCC4QgAQQsQMQxwEQ0QM6BwgAELEDEEM6BwguELEDEEM6CAguEIAEELEDOgoILhDHARCvARBDOgsILhCABBCxAxDUAjoICC4QsQMQgwE6EAguELEDEIMBEMcBENEDEEM6CAguELEDEIAEOgsIABCABBCxAxCDAToKCC4QgwEQsQMQQzoICAAQsQMQgwE6CgguELEDEIAEEAo6DQgAEIAEELEDEIMBEAo6BwguEIAEEAo6CwguEIAEEMcBEK8BOhEILhCABBCxAxCDARDHARCvAToLCC4QgAQQxwEQ0QM6BwgAEIAEEAo6CAgAEBYQHhAKOggIABAWEB4QDzoLCAAQFhAeEPEEEAo6BQgAEIYDSgQIQRgAUABYnhtg4RtoBHABeAKAAegDiAH6IpIBCjIuMTEuNC40LjGYAQCgAQHAAQE&sclient=gws-wiz-serp#lrd=0x808578028737b0df:0xf321ec987dde1c88,1,,,,"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card m-3 text-center">
                    <h4 className="card-title pt-3">
                      <strong>Google</strong>
                    </h4>
                    <div className="card-body">
                      <i className="fab fa-google fa-4x"></i>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
