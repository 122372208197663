import React from 'react';

import Adminediting from '../common/isEditing';

import { Link } from 'react-router-dom';
import Moment from 'moment';
import dataTip from 'data-tip';
import CKEditor from 'ckeditor4-react';

import blogtopicsService from '../../services/Blogservice';

class Blogarticle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogId: this.props.match.params.blogid,
      blogData: [],
      email: '',
      firstName: '',
      lastName: '',
      roleId: '',
      userId: '',
    };
  }

  selectblogArticle = async () => {
    //console.log(this.state);
    const selectArticle = await blogtopicsService.selectblogArticle({
      blogId: this.state.blogId,
    });

    let checkfileName = selectArticle[0].blog_thumbnail;

    checkfileName = checkfileName.replace(/\s/g, '');

    this.setState({
      blogData: selectArticle,
      blogAuthor: selectArticle[0].blog_author,
      blogCategory: selectArticle[0].blog_category,
      blogContent: selectArticle[0].blog_content,
      blogThumbnail: checkfileName,
    });

    // console.log(test);
  };

  userData = async () => {
    // Check if user is logged in
    if (localStorage.getItem('userData') !== null) {
      const userObject = JSON.parse(localStorage.getItem('userData'));

      await this.setState({
        email: userObject.email,
        firstName: userObject.firstName,
        lastName: userObject.lastName,
        roleId: userObject.role_id,
        userId: userObject.user_id,
      });
    }
  };

  setBlogProperty(propName, propValue) {
    // console.log(propValue);
    // console.log(propName);
    this.setState((state) => {
      state.blogData[0][propName] = propValue;
    });

    // console.log(this.state);
  }

  fileUpload = (fileName, filePayload, fileProps) => {
    var dataTypeURL = new FileReader();

    dataTypeURL.onload = (filePayload) => {
      this.setState((state) => {
        state.blogData[0]['fileName'] = fileProps[0].name;
        state.blogData[0]['fileSize'] = fileProps[0].size;
        state.blogData[0]['fileType'] = fileProps[0].type;
        state.blogData[0]['filePayload'] = filePayload.target.result;
      });
    };

    //console.log(fileData);

    dataTypeURL.readAsDataURL(fileProps[0]);
  };

  cancelBlog = (editingState) => {
    this.setState({
      isEditing: editingState,
    });
  };

  editBlog = (editingState) => {
    this.setState({
      isEditing: editingState,
    });
  };

  publishBlog = (editingState) => {
    this.setState({
      isEditing: editingState,
    });

    let checkfileName = this.state.blogThumbnail.replace(' ', '_');

    console.log(checkfileName);
    setTimeout(async () => {
      this.setState({
        isLoading: false,
      });

      const uploadData = {
        blogData: this.state.blogData,
        blogAuthor: this.state.blogAuthor,
        blogCategory: this.state.blogCategory,
        blogContent: this.state.blogContent,
        blogThumbnail: checkfileName,
        blogId: this.state.blogId,
        modifiedDate: Moment(this.state.modifiedDate).format('YYYY-MM-DD'),
      };

      //console.log(uploadData);
      //Publish Changes
      const updateBlog = await blogtopicsService.editArticle(uploadData);

      // Re-render component real time
      window.location.reload(true);

      // console.log('article publish successful');
    }, 1000);
  };

  componentDidMount = async () => {
    await this.userData();
    await this.selectblogArticle();
  };

  render() {
    return (
      <div className='fluid-container'>
        <div className='blogarticleContainer'>
          {this.state.roleId === 1 ? (
            <Adminediting
              title={this.props.match.url}
              cancelBlog={this.cancelBlog}
              editBlog={this.editBlog}
              publishBlog={this.publishBlog}
            />
          ) : null}
          <div className='container'>
            <div className='blogarticleWrapper'>
              <Link to='/blog'>
                <button className='btn btn-primary'>
                  <i className='fas fa-arrow-left'> GO BACK</i>
                </button>
              </Link>
              {this.state.isEditing === true ? (
                <input
                  type='text'
                  name='blogCategory'
                  onChange={(e) =>
                    this.setBlogProperty('blog_category', e.target.value)
                  }
                  defaultValue={this.state.blogCategory}
                />
              ) : (
                <h2 className='blogCategory pt-5'>{this.state.blogCategory}</h2>
              )}

              <p className='blogAuthor'>
                <i className='fas fa-user-circle fa-2x' />{' '}
                {this.state.blogAuthor}
              </p>
              <p className='blogarticleDate text-right'>
                {this.state.dateCreated}
              </p>
              <div className='row'>
                <div
                  className='col-md-12 blogarticleBanner'
                  style={{
                    backgroundImage: `url(${
                      'https://richmondpostacutecarebucket.s3-us-west-1.amazonaws.com/richmondstaging/' +
                      this.state.blogThumbnail
                    })`,
                  }}
                />
                {this.state.isEditing === true ? (
                  <input
                    type='file'
                    className='d-block'
                    name='blogPicture'
                    onChange={(e) =>
                      this.fileUpload(
                        'blog_picture',
                        'blog_payload',
                        e.target.files
                      )
                    }
                  />
                ) : null}
                {this.state.isEditing === true ? (
                  <CKEditor
                    style={{ marginTop: '20px', width: '100%' }}
                    data={this.state.blogContent}
                    onChange={(event, editor) => {
                      const data = event.editor.getData();
                      this.setBlogProperty('blog_content', data);
                    }}
                  />
                ) : (
                  <div
                    className='blogarticleContent'
                    dangerouslySetInnerHTML={{ __html: this.state.blogContent }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Blogarticle;
