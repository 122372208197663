import React from "react";

import Calltoaction from "./Calltoaction";
import dataTip from "data-tip";

import Messageheader from "./Messageheader";

import { Link, NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Sidebar from "./Sidebar";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuVisible: true,
      email: "",
      firstName: "",
      lastName: "",
      roleId: "",
      userId: "",
      isLoggedin: false,
      showscrollTop: false,
      isDropdownOpen: false,
      isMobile: window.innerWidth <= 990, // Define mobile device based on screen width
    };
  }

  // Fixed Nav Scroll
  handleScroll = () => {
    this.setState({
      scroll: window.scrollY,
    });
  };

  openModal = () => {
    this.setState({
      show: true,
    });
  };

  closeModal = () => {
    this.setState({
      show: false,
    });
  };

  logOut = () => {
    // Kill the session
    localStorage.clear();
    // Go back to home page
    this.props.history.push("/");
  };

  userData = async () => {
    // Check if user is logged in
    if (localStorage.getItem("userData") !== null) {
      const userObject = JSON.parse(localStorage.getItem("userData"));

      await this.setState({
        isLoggedin: true,
        email: userObject.email,
        firstName: userObject.firstName,
        lastName: userObject.lastName,
        roleId: userObject.role_id,
        userId: userObject.user_id,
      });
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      this.setState({
        showscrollTop: true,
      });
    } else {
      this.setState({
        showscrollTop: false,
      });
    }
  };

  componentDidMount = async () => {
    window.addEventListener("resize", this.handleResize);
    // Scroll to top on every page transition
    window.scrollTo(0, 0);
    // Scroll to top button

    window.addEventListener("scroll", this.toggleVisibility, { passive: true });

    // Check if user is logged in
    await this.userData();
    const el = document.querySelector(".bottomHeader");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  };

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `${this.state.height}px`)
      : (document.body.style.paddingTop = 0);
  }

  handleMouseEnter = () => {
    this.setState({ isDropdownOpen: true });
  };

  handleMouseLeave = (e) => {
    // Check if the mouse is leaving the entire dropdown
    if (!this.dropdownRef.current.contains(e.relatedTarget)) {
      this.setState({ isDropdownOpen: false });
    }
  };

  componentWillUnmount() {
    // Have to unmount component that adds the event listener or memory leak will happen. This fixes it.
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll, { passive: true });
    window.removeEventListener("scroll", this.toggleVisibility, {
      passive: true,
    });
  }

  handleResize = () => {
    // Update isMobile state based on screen width
    this.setState({
      isMobile: window.innerWidth <= 990,
    });
  };

  render() {
    const { isDropdownOpen, isMobile } = this.state;
    return (
      <div className="container-fluid header">
        <div className="topHeader">
          <div className="container">
            <div className="topHeader" />
            <div className="left">
              <div className="phoneNumber">
                <a href="tel:5104266969">
                  <i className="fas fa-phone-volume">
                    <span>Admissions Line: (510) 426-6969</span>
                  </i>
                </a>
                <a href="tel:4152502721">
                  <i className="fas fa-phone-volume">
                    <span>Administrator: (415) 250-2721</span>
                  </i>
                </a>
                <a href="tel:5102375182">
                  <i className="far fa-building">
                    <span>Facility Line: (510) 237-5182</span>
                  </i>
                </a>
              </div>
            </div>
            <div className="center">
              <div className="logo">
                <Link to="/">
                  <img
                    src="/images/richmondlogomain.jpg"
                    alt="Richmond Post-Acute Care Logo"
                    width="inherit"
                    height="100px"
                  />
                </Link>
              </div>
            </div>
            <div className="right">
              <div className="socialContainer">
                <a
                  href="https://www.youtube.com/@richmondpostacute"
                  target="_blank"
                  alt="Richmond Post-Acute Care YouTube"
                  title="Richmond Post-Acute-Care YouTube"
                >
                  <i className="fab fa-youtube fa-2x" />
                </a>
                <a
                  href="https://www.facebook.com/Richmond-Post-Acute-Care-113023083404859/"
                  target="_blank"
                  alt="Richmond Post-Acute Care Facebook"
                  title="Richmond Post-Acute Care Facebook"
                >
                  <i className="fab fa-facebook-f fa-2x" />
                  {/* <p id="socialIcons">Facebook</p> */}
                </a>

                <a
                  target="_blank"
                  href="https://www.yelp.com/biz/richmond-post-acute-care-richmond"
                  alt="Richmond Post-Acute Care Yelp"
                  title="Richmond Post-Acute Care Yelp"
                >
                  <i className="fab fa-yelp fa-2x" />
                  {/* <p id="socialIcons">Yelp</p> */}
                </a>
                {this.state.isLoggedin === true ? (
                  <React.Fragment>
                    <Link
                      className="profile data-tip-bottom"
                      data-tip={
                        this.state.firstName + " " + this.state.lastName
                      }
                      to="/adminDashboard"
                    >
                      <i className="fas fa-user-circle fa-2x" />
                    </Link>
                    <Link
                      className="logOut data-tip-bottom"
                      data-tip="Log Out"
                      onClick={this.logOut}
                      to="#"
                    >
                      <i className="fas fa-sign-out-alt fa-2x" />
                    </Link>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <hr className="break-line" />
        <div
          className={
            this.state.scroll > this.state.top
              ? "bottomHeader scrolled"
              : "bottomHeader"
          }
        >
          <div className="container Navigation">
            <nav className="navbar navbar-expand-lg">
              {/* {this.state.menuVisible ? (
                <i
                  className="fas fa-bars fa-2x navbar-toggler"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => this.setState({ menuVisible: false })}
                />
              ) : (
                <i
                  className="fas fa-times fa-2x navbar-toggler"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => this.setState({ menuVisible: true })}
                />
              )} */}

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active-link"
                      className="nav-link"
                      exact
                      to="/"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      activeClassName="active-link"
                      className="nav-link"
                      exact
                      to="/videos"
                    >
                      Videos
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      activeClassName="active-link"
                      className="nav-link"
                      exact
                      to="/Blog"
                    >
                      Blog
                    </NavLink>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded={isDropdownOpen ? "true" : "false"}
                    >
                      About Us
                    </a>
                    <div
                      className={`dropdown-menu ${
                        isDropdownOpen ? "show" : ""
                      }`}
                      aria-labelledby="navbarDropdown"
                    >
                      <NavLink
                        activeClassName="active-link"
                        className="nav-link"
                        exact
                        to="/aboutUs"
                      >
                        About Us
                      </NavLink>
                      <div className="dropdown-divider" />
                      <NavLink
                        activeClassName="active-link"
                        className="nav-link"
                        exact
                        to="/testimonials"
                      >
                        Testimonials
                      </NavLink>
                      <div className="dropdown-divider" />
                      <NavLink
                        activeClassName="active-link"
                        className="nav-link"
                        exact
                        to="/Adminletter"
                      >
                        Admin Letter
                      </NavLink>

                      <div className="dropdown-divider" />

                      <NavLink
                        activeClassName="active-link"
                        className="nav-link"
                        exact
                        to="/careers"
                      >
                        Careers
                      </NavLink>
                    </div>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      activeClassName="active-link"
                      className="nav-link"
                      exact
                      to="/contactUs"
                    >
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* Mobile Menu Sidebar */}
              {isMobile && <Sidebar />}

              <Button className="btn btn-primary" onClick={this.openModal}>
                SCHEDULE A TOUR
              </Button>
            </nav>

            <Modal show={this.state.show} onHide={this.closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>SCHEDULE A TOUR</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Calltoaction handleClose={this.closeModal} />
              </Modal.Body>
            </Modal>
          </div>
        </div>
        {/* Important Message */}
        <Messageheader />
        {/* Scroll to Top */}
        {this.state.showscrollTop ? (
          <i
            className="fas fa-arrow-up"
            id="scrolltopArrow"
            title="Scroll to top"
            onClick={() => this.scrollToTop()}
          ></i>
        ) : null}
      </div>
    );
  }
}

export default Header;
