import React, { Component } from "react";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AOS from "aos"; // Animate on scroll

class Adminletter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div className="fluid-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Richmond Post-Acute Care Administrator Letter</title>
          <meta
            name="description"
            content="Richmond Post-Acute Care Administrator Letter"
          />
          <link
            rel="canonical"
            href="https://www.richmondpostacute.com/Adminletter"
          />
        </Helmet>
        <div className="fluid-container adminBanner">
          <div className="bgImage text-center mt-4">
            <div className="bgCaption text-center pt-5">
              <img src="/images/richmondfavicon.png" alt="Richmond Logo" />
              <h2 className="pt-3" data-aos="fade-down">
                A Letter from Jim Jordan
              </h2>
            </div>
            <div className="img-mini">
              <img
                className="rounded-circle"
                src="/images/richmondprojectowner.webp"
                alt="Richmond Administrator Photo"
              />
            </div>
          </div>
        </div>
        <div className="container Adminletter">
          <div className="letterContainer">
            <div className="row">
              <h1 className="py-2" data-aos="fade-down" data-aos-once="true">
                Allow me to introduce myself
              </h1>
              <p data-aos="fade-down" data-aos-once="true">
                My name is James Jordan. If it's your desire to return home
                after your hospital stay, my team can help you. Richmond Post
                Acute Care exists to serve this purpose for our patients that
                reside in the Bay Area.
              </p>
              <p data-aos="fade-down" data-aos-once="true">
                Our small capacity (30 beds) allows my team to case manage all
                aspects of your care. A registered nurse and medical doctor
                oversee your clinical condition. A change of condition such as
                an abnormal lab, weight variance, increased pain, mood
                disturbance, response to medications, amongst other aspects
                allows a complete on-going assessment with reports to you and
                the attending physician. We have patients, just like you, that
                are poised to discharge home. My newly admitted patients have
                diagnosis related to congestive heart failure, renal disease,
                stroke, generalized weakness, diabetes, obesity, osteomyelitis
                and sepsis.
              </p>
              <p data-aos="fade-down" data-aos-once="true">
                We are a 5 Star facility with the highest rankings Medicare
                (CMS) allows. We are selective in who we can accept to ensure
                satisfaction and positive clinical outcomes. If you need to
                discuss your care and plan in private, you can call or text me
                at <a href="tel:4152502721">(415) 250-2721</a>. I'd be pleased
                to meet and discuss if we are the right fit for you.{" "}
              </p>
              <p data-aos="fade-down" data-aos-once="true">
                We pride ourselves on a{" "}
                <strong>clean, bright, no odor facility</strong>, with engaged
                staff ready to assist you in all aspects of your care. Our
                patient outcomes are the reason we receive referrals from all
                over the Bay Area.{" "}
              </p>
              <p data-aos="fade-down" data-aos-once="true">
                We align resources such as home health, medical equipment, home
                visits and follow up and schedule your primary care physician
                follow up appointment. In addition, We can assist in
                transporting you to appointments. Have a special need during
                your stay? Our social service team can help including complete
                any redetermination for Medicaid, apply for In Home Support
                Services, assist in your home is safe and ready to receive you
                on discharge.{" "}
              </p>
              <p data-aos="fade-down" data-aos-once="true">
                Our service is differentiated from competitors due our staff
                attention to detail. The clinical outcomes in therapy have been
                extraordinary. We make it a goal to overcome any physical
                challenge. Our discharge planning can start at bedside in the
                hospital. This can be an important time to begin informing our
                team of your needs, ensuring the right placement and identify
                any psycho-social challenge to getting home safely and swiftly.{" "}
              </p>
              <p data-aos="fade-down" data-aos-once="true">
                We offer hope, physical rehabilitation and a committed,
                competent team where problem solving is the norm and
                expectation.
              </p>

              <p data-aos="fade-down" data-aos-once="true">
                <strong>Professionals involved in your care:</strong>
              </p>
              <div
                className="bulletList"
                data-aos="fade-down"
                data-aos-once="true"
              >
                <ul>
                  <li>Medical doctors</li>
                  <li>Registered nurses</li>
                  <li>Administrator</li>
                  <li>Social service designee</li>
                  <li>Pharmacist</li>
                  <li>Registered dietician</li>
                  <li>Podiatrist</li>
                  <li>Licensed vocational nurses</li>
                  <li>Certified nurse assistants</li>
                  <li>Certified dietary manager</li>
                </ul>
              </div>

              <p data-aos="fade-down" data-aos-once="true">
                Our vast experience will benefit you in your transition home.
              </p>

              <p data-aos="fade-down" data-aos-once="true">
                Finally, if I can be of assistance I am also always available to
                help with your Post-Acute Care needs.
              </p>
              <p data-aos="fade-down" data-aos-once="true">
                <strong>James Jordan, Administrator</strong>
              </p>
              <p data-aos="fade-down" data-aos-once="true">
                Phone Number: <a href="tel:4152502721">(415) 250-2721</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Adminletter;
