import React, { Component } from "react";

import MyTheme from "../common/Theme";

import Formservice from "../../services/Formservice";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AOS from "aos"; // Animate on scroll
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

import { MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Voipcall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      fullNameError: "",
      email: "",
      errorError: "",
      phoneNumber: "",
      phoneNumberError: "",
      contactMethod: "",
      contactMethodError: "",
      questions: "",
      textmask: "(1  )    -    ",

      captchaVerify: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange = (e, string) => {
    this.setState({
      [e.target.name]: event.target.value,
      [string]: event.target.value,
    });
  };

  captchaVerify = (value) => {
    const capVerify = value;
    if (capVerify !== "") {
      this.setState({
        captchaVerify: true,
      });
    }
    //console.log(this.state);
  };

  validateForm = () => {
    let isError = false;
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    //let phoneRegex
    const errors = {
      fullNameError: "",
      emailError: "",
      phoneNumberError: "",
      contactMethodError: "",
      captchaError: "",
    };

    if (this.state.fullName === "") {
      isError = true;
      errors.fullNameError = "Please enter your full name";
    }

    if (this.state.email.indexOf("@") === -1) {
      isError = true;
      errors.emailError = "Please enter a valid email address";
    }

    if (this.state.contactMethod === "") {
      isError = true;
      errors.contactMethodError = "Please select a perferred contact method";
    }

    if (phoneRegex.test(this.state.phoneNumber) === false) {
      isError = true;
      errors.phoneNumberError = "Please enter a valid phone number";
    }

    if (this.state.captchaVerify === false) {
      isError = true;
      errors.captchaError = "* Please verify you're not a robot";
    }

    this.setState({
      ...this.state,
      ...errors,
      isError,
    });

    return isError;
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const err = this.validateForm();

    //console.log(this.state);

    if (!err) {
      // clear the form
      this.setState({
        fullName: "",
        fullNameError: "",
        email: "",
        emailError: "",
        questions: "",
        contactMethod: "",
        contactMethodError: "",
        phoneNumber: "",
        phoneNumberError: "",
        textmask: "(1  )    -    ",
      });

      const successData = {
        fullName: this.state.fullName,
        email: this.state.email,
        questions: this.state.questions,
        phoneNumber: this.state.phoneNumber,
        contactMethod: this.state.contactMethod,
      };

      const careerData = await Formservice.voipcallAuth(successData);

      //console.log("successful data submission", successData);

      // alert(
      //   "Thank you. Your message has been successfully sent. We'll get back to you as soon as we can."
      // );
    }
  };

  componentDidMount() {
    AOS.init();
  }

  render() {
    const { textmask } = this.state;
    return (
      <div className="voipContainer">
        <div className="fluid-container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Richmond Post-Acute Voip Call Form Information</title>
            <meta
              name="description"
              content="Richmond Post-Acute Care Voip Call Information Form"
            />
            <link
              rel="canonical"
              href="https://www.richmondpostacute.com/voipCall"
            />
          </Helmet>
          <div className="container">
            <div className="voipmainContainer">
              <div className="row">
                <div className="col-md-4 left">
                  <div className="callInfo text-center">
                    <i className="fas fa-phone-square fa-4x"></i>
                    <h3 className="py-5">Scheduling a Call</h3>
                    <p>
                      To get started, simply fill out this form and we'll get in
                      contact you as soon as possible.
                    </p>
                  </div>
                </div>
                <div className="col-md-8 right">
                  <h1 className="text-center py-5">
                    Scheduling Call Information
                  </h1>

                  {/* Form */}

                  <MuiThemeProvider theme={MyTheme}>
                    <form
                      method="POST"
                      noValidate
                      autoComplete="off"
                      onSubmit={(e) => this.onSubmit(e)}
                    >
                      <div>
                        <Grid container alignItems="center" direction="column">
                          <Grid item xs={12}>
                            <InputLabel
                              htmlFor="age-native-simple"
                              style={
                                this.state.contactMethodError
                                  ? { color: "red" }
                                  : null
                              }
                            >
                              Preferred Contact Method
                            </InputLabel>
                            <Select
                              native
                              name="contactMethod"
                              value={this.state.contactMethod}
                              onChange={(e) => this.onChange(e)}
                              inputProps={{
                                name: "contactMethod",
                              }}
                              error={
                                this.state.contactMethodError ? true : false
                              }
                            >
                              <option value="" />
                              <option value={"Skype"}>Skype</option>
                              <option value={"Zoom"}>Zoom</option>
                            </Select>
                            {this.state.contactMethodError ? (
                              <FormHelperText style={{ color: "red" }}>
                                {this.state.contactMethodError}
                              </FormHelperText>
                            ) : null}
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              name="fullName"
                              value={this.state.fullName}
                              type="text"
                              label="Full Name"
                              error={this.state.fullNameError ? true : false}
                              helperText={this.state.fullNameError}
                              variant="outlined"
                              onChange={(e) => this.onChange(e)}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              name="email"
                              type="email"
                              value={this.state.email}
                              label="Email Address"
                              error={this.state.emailError ? true : false}
                              helperText={this.state.emailError}
                              variant="outlined"
                              onChange={(e) => this.onChange(e)}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <FormControl>
                              <InputLabel
                                htmlFor="formatted-text-mask-input"
                                style={
                                  this.state.phoneNumberError
                                    ? { color: "red" }
                                    : null
                                }
                              >
                                {this.state.phoneNumberError
                                  ? this.state.phoneNumberError
                                  : "Phone Number"}
                              </InputLabel>
                              <Input
                                type="tel"
                                value={textmask}
                                name="phoneNumber"
                                onChange={(e) => this.onChange(e, "textmask")}
                                inputComponent={TextMaskCustom}
                                error={
                                  this.state.phoneNumberError ? true : false
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              multiline
                              rows="5"
                              name="questions"
                              value={this.state.questions}
                              label="Questions/Comments"
                              variant="outlined"
                              onChange={(e) => this.onChange(e)}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <ReCAPTCHA
                              sitekey={process.env.RECAPTCHA_API_KEY}
                              onChange={this.captchaVerify}
                            />
                            {this.state.captchaVerify === false ? (
                              <p style={{ textAlign: "left", color: "red" }}>
                                {this.state.captchaError}
                              </p>
                            ) : null}
                          </Grid>

                          <Grid item xs={12}>
                            <Button type="submit" variant="outlined">
                              SUBMIT
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </form>
                  </MuiThemeProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Voipcall;
