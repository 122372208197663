export const VideoDataByYear = {
  currentYear: [
    {
      id: 1,
      video:
        "https://richmondpostacutecarebucket.s3.us-west-1.amazonaws.com/richmondpostacutecareassets/RICH-Walk-V1_1.mp4",
      poster: "/images/posters/virtualtour.jpg",
      tag: "Information",
      header: "A Day at Richmond Post-Acute",
      paragraph:
        "Experience our state-of-the-art nursing facility like never before with our amazing virtual walkthrough services, providing you with a comprehensive and engaging view of our facility from the comfort of your own home!",
    },
    {
      id: 2,
      video:
        "https://richmondpostacutecarebucket.s3.us-west-1.amazonaws.com/richmondpostacutecareassets/RICH-Promo-V1_1.mp4",
      poster: "/images/posters/meet-owner-vod-thumbnail.jpg",
      tag: "Information",
      header: "Meet The Owner",
      paragraph:
        "All team members at Richmond Post-Acute Care have been carefully selected with specialized expertise by Jim Jordan to make sure that patients have the best experience in their recovery so that they can safely return home.",
    },
    {
      id: 3,
      video:
        "https://richmondpostacutecarebucket.s3.us-west-1.amazonaws.com/richmondpostacutecareassets/RICH-VirtWlk-V1_1.mp4",
      poster: "/images/posters/walkthrough.jpg",
      tag: "Featured",
      header: "Virtual Walkthrough",
      paragraph:
        "Get a sneak peek of our nursing facility's virtual walkthrough - an immersive and interactive experience that allows you to explore every corner of our state-of-the-art facility from the convenience of your computer or mobile device!",
    },
  ],

  prior: [
    {
      id: 1,
      video:
        "https://richmondpostacutecarebucket.s3.us-west-1.amazonaws.com/richmondpostacutecareassets/Richmond_JJ_Testimony_1.mp4",
      poster: "/images/2022-vod-meet.webp",
      tag: "Featured",
      header: "Meet Jim Jordan",
      paragraph:
        "Richmond Post-Acute Care is an exceptional facility that is reputable and offers the highest and best quality of care to our patients. Learn more about James' philosophy of care and how he can help you and your loved one.",
    },
  ],
};
