import React from "react";

import MyTheme from "../common/Theme";

import Formservice from "../../services/Formservice";

import { Helmet } from "react-helmet";
import AOS from "aos"; // Animate on scroll

import { MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ReCAPTCHA from "react-google-recaptcha";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      fullNameError: "",
      email: "",
      emailError: "",
      message: "",
      messageError: "",
      captchaVerify: false,
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  captchaVerify = (value) => {
    const capVerify = value;
    if (capVerify !== "") {
      this.setState({
        captchaVerify: true,
      });
    }
    //console.log(this.state);
  };

  formValidate = () => {
    let isError = false;
    const errors = {
      fullNameError: "",
      emailError: "",
      messageError: "",
      captchaError: "",
    };

    if (this.state.fullName === "") {
      isError = true;
      errors.fullNameError = "* Please enter your full name";
    }

    if (this.state.email.indexOf("@") === -1) {
      isError = true;
      errors.emailError = "* Please enter a valid email address";
    }

    if (this.state.message.length > 250) {
      isError = true;
      errors.messageError =
        "* Please enter a message no more than 250 characters";
    }

    if (this.state.captchaVerify === false) {
      isError = true;
      errors.captchaError = "* Please verify you're not a robot";
    }

    this.setState({
      ...this.state,
      ...errors,
      isError,
    });

    return isError;
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const err = this.formValidate();

    if (!err) {
      //clear the form
      this.setState({
        fullName: "",
        fullNameError: "",
        message: "",
        email: "",
        emailError: "",
        messageError: "",
      });

      const successData = {
        fullName: this.state.fullName,
        email: this.state.email,
        message: this.state.message,
      };

      const contactData = await Formservice.contactAuth(successData);

      alert(
        "Thank you. Your message has been successfully sent. We'll get back to you as soon as we can."
      );
    }
  };

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div className="contactContainer">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Richmond Post-Acute Care Contact Page</title>
          <meta name="description" content="Richmond Post-Acute Care Contact" />
          <link
            rel="canonical"
            href="https://www.richmondpostacute.com/contactUs"
          />
        </Helmet>

        <div className="fluid-container">
          <div className={"bgimage"}>
            <div className={"container"}>
              <div className={"row justify-content-md-center"}>
                <div className={"col-md-6 col-md-auto"}>
                  <div
                    className={"bgContent"}
                    style={{
                      background: "#000",
                      borderRadius: "25px",
                      opacity: 0.8,
                    }}
                  >
                    <div data-aos={"fade-down"} data-aos-once={"true"}>
                      <h1
                        className={"text-center py-3"}
                        style={{ color: "#35a768" }}
                      >
                        Contact Us
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="formHeader">
              <p className="text-center pt-5">
                We will get in contact with you as soon as we can.
              </p>
            </div>
            <div className="row">
              <div className="col-md-8">
                <p id="formSub">How can we assist you?</p>
                <div className="formContainer">
                  <MuiThemeProvider theme={MyTheme}>
                    <form
                      method="POST"
                      noValidate
                      autoComplete="off"
                      onSubmit={(e) => this.onSubmit(e)}
                    >
                      <div className="formContainer">
                        <Grid container>
                          <Grid item xs={12}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              name="fullName"
                              value={this.state.fullName}
                              type="text"
                              label="Full Name"
                              error={this.state.fullNameError ? true : false}
                              helperText={this.state.fullNameError}
                              variant="outlined"
                              onChange={(e) => this.onChange(e)}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              name="email"
                              type="email"
                              value={this.state.email}
                              label="Email Address"
                              error={this.state.emailError ? true : false}
                              helperText={this.state.emailError}
                              variant="outlined"
                              onChange={(e) => this.onChange(e)}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              multiline
                              rows="5"
                              name="message"
                              value={this.state.message}
                              error={this.state.messageError ? true : false}
                              helperText={this.state.messageError}
                              label="Message"
                              variant="outlined"
                              onChange={(e) => this.onChange(e)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ReCAPTCHA
                              sitekey={process.env.RECAPTCHA_API_KEY}
                              onChange={this.captchaVerify}
                            />
                            {this.state.captchaVerify === false ? (
                              <p style={{ textAlign: "left", color: "red" }}>
                                {this.state.captchaError}
                              </p>
                            ) : null}
                          </Grid>

                          <Grid item xs={12}>
                            <Button variant="outlined" type="submit">
                              SUBMIT
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </form>
                  </MuiThemeProvider>
                </div>
              </div>
              <div className="col-md-4">
                <div className="companyInfo">
                  <div className="row">
                    <div className="col-md-12">
                      <h4>Address</h4>
                      <a
                        href="https://www.google.com/maps/dir/38.5811109,-121.4113196/richmond+post+acute+care/@38.2614014,-122.4451111,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x808578028737b0df:0xf321ec987dde1c88!2m2!1d-122.347785!2d37.946331"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-map-marker-alt fa-2x" />
                        955 23rd St, Richmond, CA 94804
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <h4>Phone</h4>
                      <div className="left">
                        <i className="fas fa-phone fa-2x" />
                      </div>
                      <div className="right">
                        <a href="tel:5104266969">
                          Admissions Line: (510) 426-6969
                        </a>
                        <a href="tel:4152502721">
                          Administrator: (415) 250-2721
                        </a>
                        <a href="tel:5102375182">
                          Facility Line: (510) 237-5182
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <h4>Email</h4>
                      <p>
                        <i className="fas fa-envelope fa-2x" />
                        <a href="mailto:info@richmondpostacute.com">
                          info@richmondpostacute.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <h4>Business Hours</h4>
                      <p>
                        <i className="fas fa-hourglass-half fa-2x" />
                        Open 24/7
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
