import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import { Button } from "react-bootstrap";

import AOS from "aos"; // Animate on scroll

const VisitorGuidelines = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="visitor-guidelines-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Richmond Post-Acute Visitor Guidelines</title>
        <meta
          name="description"
          content="Richmond Post-Acute latest visitor guidelines and helpful information about our facility"
        />
        <link
          rel="canonical"
          href="https://www.richmondpostacute.com/visitorGuidelines"
        />
      </Helmet>
      {/* Zoom Component */}
      <div className="container" data-aos="fade-up" data-aos-once="true">
        <div className="callHeader my-4">
          <div className="row">
            <div className="d-flex justify-content-center align-items-center col-md-6 px-5">
              <div className="schedulecallContainer pt-3">
                <h2>Schedule a Visit</h2>
                <p className="py-3">
                  We understand that technology may not come easily to some.
                  Therefore, we provide flexibility on setting up meetings with
                  Zoom or Skype technology applications to better meet the needs
                  of our residents. By scheduling a call, we can further address
                  your requests and provide the best post-acute care possible.
                </p>
                <Link to="/voipCall">
                  <Button className="btn btn-outline">GET STARTED</Button>
                </Link>
              </div>
            </div>
            <div className="col-md-6 p-5 text-center">
              <div className="skype py-3">
                <img
                  src="/images/SkypeIconop.png"
                  alt="Skype Icon"
                  title="Skype Icon"
                  width="150px"
                  height="150px"
                />
              </div>
              <hr className="hr-text" data-content="or" />
              <div className="zoom py-3">
                <img
                  src="/images/ZoomIconop.png"
                  alt="Zoom Icon"
                  title="Zoom Icon"
                  width="150px"
                  height="150px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorGuidelines;
