import React from "react";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AOS from "aos"; // Animate on scroll

import OwlCarousel from "react-owl-carousel";
import "../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSelected: 0,
      headerContent: [
        {
          title: "About Us",
          img: "/images/about-richmond.webp",
          alt: "Richmond Post-Acute Care About Us",
          header: "About Richmond Post-Acute Care",
          content:
            "At Richmond Post-Acute Care, we're here to help you get back to your best self. Our expert team specializes in post-acute care, offering IV therapy, wound care, diabetic care, stroke care (CVA), respiratory care, orthopedic care, and constant monitoring to ensure prompt reporting of any changes in your condition to your physician. We're proud to be known as one of the top post-acute care facilities in the Bay Area, and we're dedicated to delivering the best possible clinical outcomes for every patient we serve. Come visit us and see how we can help you get back to living your best life!",
        },
        {
          title: "Meet the Owners",
          img: "/images/richmondprojectowner.webp",
          alt: "Richmond Post-Acute Team",
          header: "Richmond Post-Acute Team",
          content:
            "Combining 50 years in the healthcare industry, James Jordan and Darrell Price teamed up to create a clinical experience designed to return you back home. Our managers, staff, and all allied professionals exist to obtain the highest most practical functionality in your quest to return home. Our management philosophy is to provide the leadership, education, and tools for staff to perform their jobs at the highest level.",
        },
        // {
        //   title: "Affiliations",
        //   img: "/images/mmaboutaffiliations.webp",
        //   alt: "Richmond Post-Acute Care Affiliations",
        //   header: "Richmond Post-Acute Care Affiliations",
        //   content:
        //     "Mountain Manor Skilled Nursing Overall 5-Star Medicare Rating and is a member of Dignity Health's Preferred Network list. As the best Skilled Nursing Facility in Carmichael, CA. We offer Physical, Occupational and Speech Therapies, in addition to compassionate care by Licensed RN's, LVN's and staff."
        // }
      ],
    };
  }

  showContent = (e, listId) => {
    this.setState({
      activeSelected: listId,
    });
  };

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div className={"aboutContainer"}>
        <Helmet>
          <meta charSet={"utf-8"} />
          <title>Richmond Post-Acute Care About Us</title>
          <meta
            name={"description"}
            content={"Richmond Post-Acute Care History | About Us"}
          />
          <link
            rel={"canonical"}
            href={"https://www.richmondpostacute.com/aboutUs"}
          />
        </Helmet>
        <div className={"fluidContainer"}>
          <div className={"bgimage"}>
            <div className={"container"}>
              <div className={"row justify-content-md-center"}>
                <div className={"col-md-6 col-md-auto"}>
                  <div
                    className={"bgContent"}
                    style={{
                      background: "#000",
                      borderRadius: "25px",
                      opacity: 0.8,
                    }}
                  >
                    <div data-aos={"fade-down"} data-aos-once={"true"}>
                      <h1
                        className={"text-center"}
                        style={{ color: "#35a768" }}
                      >
                        About Us
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"whymmContainer"}>
          <div className={"container"}>
            <div className={"h2Header"}>
              <h2>Why Richmond Post-Acute Care</h2>
            </div>
            <div className={"listHeaders"}>
              <div className={"row justify-content-md-center"}>
                <div className={"col-md-12 text-center"}>
                  <ul>
                    {this.state.headerContent.map((rows, index) => (
                      <li
                        key={`list- ${index}`}
                        className={
                          this.state.activeSelected === index
                            ? "headerList selected"
                            : "headerList"
                        }
                        onClick={(e) => this.showContent(e, index)}
                      >
                        {rows.title}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={"contentContainer"}>
            <div className={"fluid-container"}>
              <div className={"container"}>
                {this.state.headerContent.map((rows, index) =>
                  this.state.activeSelected === index ? (
                    <div
                      className={"row align-items-center"}
                      key={`list- ${index}`}
                    >
                      <div className={"col-md-6"}>
                        <img
                          src={rows.img}
                          alt={rows.alt}
                          width="100%"
                          height="auto"
                        />
                      </div>
                      <div className={"col-md-6"}>
                        <h3>{rows.header}</h3>
                        <p>{rows.content}</p>
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </div>
          {/* <div className="quoteContainer">
            <div className="fluid-container">
              <div className="container">
                <div className="quoteHeader text-center">
                  <div data-aos="fade-down" data-aos-once="true">
                    <h3>What Our Customers Say</h3>
                  </div>
                </div>
                <div className="quote">
                  <i className="fas fa-quote-left fa-2x" />
                </div>
                <div className="quoteSlider">
                  <OwlCarousel
                    className="owl-theme"
                    items={1}
                    loop={true}
                    autoplay={true}
                    autoplayTimeout={10000}
                  >
                    <div className="item">
                      <p>
                        Mountain manor is a phenomenal facility your loved ones
                        are truly cared for here! The staff is highly
                        knowledgeable caring and you can tell they truly care
                        about what they do, the food is unlike any other
                        facility and is flavorful and savory! Would recommend to
                        anyone! 5 stars all around!
                      </p>
                      <p id="quoteName">Emilia E. Sacramento, CA</p>
                    </div>
                    <div className="item">
                      <p>
                        Would highly recommend this nursing facility. The staff
                        is wonderful and always very helpful. It is very clean
                        and uncluttered.
                      </p>
                      <p id="quoteName">Jacquelyn R. Elverta, CA</p>
                    </div>
                    <div className="item">
                      <p>
                        I highly recommend Mountain Manor. Their communication
                        is second to none, and I am so very grateful for the
                        compassion and kindness their staff has shown me. Thank
                        you, thank you, thank you.
                      </p>
                      <p id="quoteName">Daniel M. Sacramento, CA</p>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default About;
