import React from "react";

import { Link } from "react-router-dom";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var today = new Date();
    return (
      <div className="footerContainer" style={{ marginTop: "50px" }}>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-md-center col-lg-6 text-lg-left">
                <img
                  // className='w-50'
                  src="/images/richmondlogomain.jpg"
                  alt="Richmond Post-Acute Care Logo"
                  width="inherit"
                  height="100px"
                />
              </div>
              <div className="col-md-6 text-md-center col-lg-6 text-lg-right">
                <div className="socialfooterContainer">
                  <a
                    href="https://www.facebook.com/Richmond-Post-Acute-Care-113023083404859/"
                    target="_blank"
                    alt="Richmond Post-Acute Care Facebook"
                    rel="noopener noreferrer"
                    title="Facebook"
                  >
                    <i className="fab fa-facebook-f fa-2x" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.yelp.com/biz/richmond-post-acute-care-richmond"
                    alt="Richmond Post-Acute Care Yelp"
                    rel="noopener noreferrer"
                    title="Yelp"
                  >
                    <i className="fab fa-yelp fa-2x" />
                  </a>
                </div>
              </div>
            </div>
            <hr className="my-3" />

            <div className="quickLinks">
              <div className="row">
                <div className="col-lg-4">
                  <h3>Relative Links</h3>
                  <ul>
                    <Link to="/accessibility">
                      <li>Accessibility</li>
                    </Link>
                    <Link to="/videos">
                      <li>Videos</li>
                    </Link>
                    <Link to="/aboutUs">
                      <li>About Us</li>
                    </Link>
                    <Link to="/testimonials">
                      <li>Testimonials</li>
                    </Link>

                    <Link to="/contactUs">
                      <li>Contact Us</li>
                    </Link>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <h3>Leave a Review</h3>
                  <div className="reviewInfo">
                    <a
                      href="https://www.facebook.com/Richmond-Post-Acute-Care-113023083404859/"
                      target="_blank"
                      rel="noreferrer nooppener"
                      className="py-3"
                    >
                      <i
                        className="fab fa-facebook fa-3x"
                        style={{ color: "#3B5998" }}
                      />
                    </a>

                    <a
                      href="https://www.google.com/search?q=richmond+post+acute+care&ei=MqEGZJ-lCrKy0PEPip-z2As&ved=0ahUKEwifhp_J4sj9AhUyGTQIHYrPDLsQ4dUDCBA&uact=5&oq=richmond+post+acute+care&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzILCC4QrwEQxwEQgAQyBggAEBYQHjoECAAQQzoFCAAQkQI6CwguEIAEELEDEIMBOgUIABCABDoFCC4QgAQ6EQguEIAEELEDEIMBEMcBENEDOhEILhCABBCxAxDHARDRAxDUAjoICAAQgAQQsQM6BggAEAoQQzoKCC4QxwEQ0QMQQzoOCC4QgAQQsQMQxwEQ0QM6BwgAELEDEEM6BwguELEDEEM6CAguEIAEELEDOgoILhDHARCvARBDOgsILhCABBCxAxDUAjoICC4QsQMQgwE6EAguELEDEIMBEMcBENEDEEM6CAguELEDEIAEOgsIABCABBCxAxCDAToKCC4QgwEQsQMQQzoICAAQsQMQgwE6CgguELEDEIAEEAo6DQgAEIAEELEDEIMBEAo6BwguEIAEEAo6CwguEIAEEMcBEK8BOhEILhCABBCxAxCDARDHARCvAToLCC4QgAQQxwEQ0QM6BwgAEIAEEAo6CAgAEBYQHhAKOggIABAWEB4QDzoLCAAQFhAeEPEEEAo6BQgAEIYDSgQIQRgAUABYnhtg4RtoBHABeAKAAegDiAH6IpIBCjIuMTEuNC40LjGYAQCgAQHAAQE&sclient=gws-wiz-serp#lrd=0x808578028737b0df:0xf321ec987dde1c88,1,,,,"
                      target="_blank"
                      rel="noreferrer nooppener"
                      className="py-3"
                    >
                      <i className="fab fa-google fa-3x"></i>
                    </a>
                    <a
                      href="https://www.yelp.com/biz/richmond-post-acute-care-richmond"
                      target="_blank"
                      rel="noreferrer nooppener"
                      className="py-3"
                    >
                      <i className="fab fa-yelp fa-3x"></i>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4">
                  <h3>Get in Contact</h3>
                  <div className="contactInfo">
                    <div className="info">
                      <i className="fas fa-map-marked">
                        <a
                          href="https://www.google.com/maps/dir/38.5811109,-121.4113196/richmond+post+acute+care/@38.2614014,-122.4451111,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x808578028737b0df:0xf321ec987dde1c88!2m2!1d-122.347785!2d37.946331"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>955 23rd St, Richmond, CA 94804</span>
                        </a>
                      </i>
                    </div>
                    <div className="info">
                      <p>
                        <i className="fas fa-phone" />
                        <a href="tel:5104266969">
                          Admissions Line: (510) 426-6969
                        </a>
                        <a href="tel:4152502721">
                          Administrator: (415) 250-2721
                        </a>
                        <a href="tel:5102375182">
                          Facility Line: (510) 237-5182
                        </a>
                      </p>
                    </div>
                    <div className="info">
                      <p>
                        <i className="fas fa-envelope" />
                        <a href="mailto:info@richmondpostacute.com">
                          info@richmondpostacute.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer">
              <footer>
                <div className="certifications text-center">
                  <img
                    src="/images/footermedicarecertified.jpg"
                    alt="Medicare Certified"
                    title="Department of Health &amp; Human Services Certified"
                    width="120px"
                    height="120px"
                  />
                  <img
                    src="/images/footercahf.jpg"
                    alt="Medicare Certified"
                    title="California Association of Health Services Certified"
                    width="120px"
                    height="120px"
                  />
                </div>
                <p className="py-2">
                  Copyright &copy; {today.getFullYear()} Richmond Post-Acute
                  Care | All Rights Reserved
                </p>
              </footer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
