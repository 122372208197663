import React, { useEffect, useState } from "react";

import yelpService from "../../services/Yelpservice";

const UserReviews = () => {
  const [users, setUsers] = useState();
  const getReviews = async () => {
    const getUsers = await yelpService.getUsers();

    setUsers(getUsers);
  };

  useEffect(() => {
    // Get the reviews
    getReviews();
  }, []);
  return (
    <div
      className="reviewsContainer"
      data-aos="fade-down"
      data-aos-offset="300"
    >
      <div className="reviewsHeader pt-3">
        <h1 className="h2Header">Reviews</h1>
        <p className="text-center pb-4">
          Take a look at what some of our customers had to say about there great
          experience with our facility.
        </p>
      </div>
      <div className="row justify-content-center ">
        <div className="col-lg-4 shadow-lg text-center p-3 rounded">
          <div className="rounded">
            <i className="fas fa-star fa-2x" />
            <i className="fas fa-star fa-2x" />
            <i className="fas fa-star fa-2x" />
            <i className="fas fa-star fa-2x" />
            <i className="fas fa-star fa-2x" />
            &nbsp;
            <span className="lead">5.0</span>
          </div>
        </div>
      </div>
      <div className="review-category mt-2 d-flex justify-content-center">
        <div className="review-header">
          <img
            src="/images/yelp-reviews.jpg"
            height={"250px"}
            width="250px"
            alt="Richmond Post-Acute Care Yelp Reviews"
          />
        </div>
      </div>
      <div className="reviewContent my-5">
        <div className="row justify-content-center text-center">
          {users ? (
            users.reviews
              .filter((data) => {
                // only return ratings that are 5
                return data.rating === 5;
              })
              .map((data, index) => (
                <div className="col-sm-12 my-3 col-lg-4 rounded" key={index}>
                  <div className="reviewsubContent shadow-lg">
                    <div className="personInfo">
                      <div className="rounded rounded-circle">
                        <i className="far fa-user fa-4x"></i>
                      </div>
                      <p className="py-3">
                        <strong>{data.user.name}</strong>
                      </p>
                      <i className="fas fa-star fa-2x"></i>
                      <i className="fas fa-star fa-2x"></i>
                      <i className="fas fa-star fa-2x"></i>
                      <i className="fas fa-star fa-2x"></i>
                      <i className="fas fa-star fa-2x"></i>
                    </div>
                    <div className="personContent">
                      <p className="p-3 text-left">
                        {data.text + " "}
                        <a
                          href={data.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          User Reviews
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div id="loading">
              <i className="fas fa-spinner fa-4x" />
              <p className="pt-2">
                <strong>Loading please wait...!</strong>
              </p>
            </div>
          )}
        </div>
      </div>
      {/* Google Reviews Hardcoded for now */}
      <div className="review-category mt-2 d-flex justify-content-center">
        <img
          src="/images/google-reviews.jpg"
          height={"250px"}
          width="250px"
          alt="Richmond Post-Acute Care Google Reviews"
        />
      </div>
      <div className="reviewContent my-5">
        <div className="row justify-content-center text-center">
          <div className="col-sm-12 my-3 col-lg-4 rounded">
            <div className="reviewsubContent shadow-lg">
              <div className="personInfo">
                <div className="rounded rounded-circle">
                  <i className="far fa-user fa-4x"></i>
                </div>
                <p className="py-3">
                  <strong>{"Abraham E."}</strong>
                </p>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
              </div>
              <div className="personContent">
                <p className="p-3 text-left">
                  Great place to serve patients. Well managed! The management
                  and DON are highly involved in the care and evaluation of the
                  treatment...{" "}
                  <a
                    href={
                      "https://www.google.com/maps/place/Richmond+Post-Acute+Care/@37.9463352,-122.3499737,17z/data=!3m1!4b1!4m5!3m4!1s0x808578028737b0df:0xf321ec987dde1c88!8m2!3d37.946331!4d-122.347785"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Reviews
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 my-3 col-lg-4 rounded">
            <div className="reviewsubContent shadow-lg">
              <div className="personInfo">
                <div className="rounded rounded-circle">
                  <i className="far fa-user fa-4x"></i>
                </div>
                <p className="py-3">
                  <strong>{"Gayle A."}</strong>
                </p>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
              </div>
              <div className="personContent">
                <p className="p-3 text-left">
                  I was treated as family, even when I was grumpy. 😊 Everyone
                  cared for me, and worked very hard to get me back on my feet.{" "}
                  <a
                    href={
                      "https://www.google.com/maps/place/Richmond+Post-Acute+Care/@37.9463352,-122.3499737,17z/data=!3m1!4b1!4m5!3m4!1s0x808578028737b0df:0xf321ec987dde1c88!8m2!3d37.946331!4d-122.347785"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Reviews
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 my-3 col-lg-4 rounded">
            <div className="reviewsubContent shadow-lg">
              <div className="personInfo">
                <div className="rounded rounded-circle">
                  <i className="far fa-user fa-4x"></i>
                </div>
                <p className="py-3">
                  <strong>{"Bobby H."}</strong>
                </p>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
              </div>
              <div className="personContent">
                <p className="p-3 text-left">
                  You know you really can't put into words that describe the
                  staff, from house keeping to the kitchen and EVERYONE in
                  between its such a pleasure to be here...{" "}
                  <a
                    href={
                      "https://www.google.com/maps/place/Richmond+Post-Acute+Care/@37.9463352,-122.3499737,17z/data=!3m1!4b1!4m5!3m4!1s0x808578028737b0df:0xf321ec987dde1c88!8m2!3d37.946331!4d-122.347785"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Reviews
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 my-3 col-lg-4 rounded">
            <div className="reviewsubContent shadow-lg">
              <div className="personInfo">
                <div className="rounded rounded-circle">
                  <i className="far fa-user fa-4x"></i>
                </div>
                <p className="py-3">
                  <strong>{"James S."}</strong>
                </p>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
              </div>
              <div className="personContent">
                <p className="p-3 text-left">
                  The staff and management at Richmond Post-Acute are 100%
                  professional the care they show for their clients is above
                  reproch...{" "}
                  <a
                    href={
                      "https://www.google.com/maps/place/Richmond+Post-Acute+Care/@37.9463352,-122.3499737,17z/data=!3m1!4b1!4m5!3m4!1s0x808578028737b0df:0xf321ec987dde1c88!8m2!3d37.946331!4d-122.347785"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Reviews
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 my-3 col-lg-4 rounded">
            <div className="reviewsubContent shadow-lg">
              <div className="personInfo">
                <div className="rounded rounded-circle">
                  <i className="far fa-user fa-4x"></i>
                </div>
                <p className="py-3">
                  <strong>{"Allie S."}</strong>
                </p>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
              </div>
              <div className="personContent">
                <p className="p-3 text-left">
                  THIS IS THE BEST PLACE! My brother and I toured with James
                  (the owner) we knew he really cared about this facility. It’s
                  clean, oder free...{" "}
                  <a
                    href={
                      "https://www.google.com/maps/place/Richmond+Post-Acute+Care/@37.9463352,-122.3499737,17z/data=!3m1!4b1!4m5!3m4!1s0x808578028737b0df:0xf321ec987dde1c88!8m2!3d37.946331!4d-122.347785"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Reviews
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 my-3 col-lg-4 rounded">
            <div className="reviewsubContent shadow-lg">
              <div className="personInfo">
                <div className="rounded rounded-circle">
                  <i className="far fa-user fa-4x"></i>
                </div>
                <p className="py-3">
                  <strong>{"Brian W."}</strong>
                </p>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
              </div>
              <div className="personContent">
                <p className="p-3 text-left">
                  Jim Jordan and his staff at Richmond Post-Acute are excellent
                  they do an excellent job with wound care as well as physical
                  therapy I highly recommend...{" "}
                  <a
                    href={
                      "https://www.google.com/maps/place/Richmond+Post-Acute+Care/@37.9463352,-122.3499737,17z/data=!3m1!4b1!4m5!3m4!1s0x808578028737b0df:0xf321ec987dde1c88!8m2!3d37.946331!4d-122.347785"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Reviews
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserReviews;
