import React from "react";

import { Link } from "react-router-dom";

import $ from "jquery";

// Pure Component not Class
class Message extends React.Component {
  componentDidMount = () => {
    $(".messageHeader").slideDown(1000);
  };

  render() {
    return (
      <div className="messageHeader">
        <div className="messageContent py-2">
          <Link style={{ color: "black" }} to="/visitorGuidelines">
            <p className="text-center">
              For more information on scheduling a visit please visit our
              Schedule a Visit
            </p>
          </Link>
        </div>
      </div>
    );
  }
}

export default Message;
