import React, { useEffect, useState, lazy, Suspense } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

import Header from "./common/Header";
import Footer from "./common/Footer";

import LoadingBar from "react-top-loading-bar";

const Blogtopics = lazy(() => import("./components/Blogtopics"));
const Blogarticle = lazy(() => import("./components/Blogarticle"));
const Admindashboard = lazy(() => import("./components/Admindashboard"));
const Services = lazy(() => import("./components/Services"));
const Videos = lazy(() => import("./components/Videos"));
const Testimonials = lazy(() => import("./components/Testimonials"));
const Adminletter = lazy(() => import("./components/Adminletter"));
const About = lazy(() => import("./components/About"));
const Careers = lazy(() => import("./components/Careers"));
const Contact = lazy(() => import("./components/Contact"));
const Nomatch = lazy(() => import("./components/Nomatch"));
const Intranet = lazy(() => import("./components/Intranet"));
const Accessibility = lazy(() => import("./components/Accessibility"));
const Voipcall = lazy(() => import("./components/Voipcall"));
const VisitorGuidelines = lazy(() => import("./components/VisitorGuidelines"));
const Feedback = lazy(() => import("./components/Feedback"));

const Routes = ({ history }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    history.listen(() => {
      window.scrollTo(0, 0);
      setProgress(100);
    });
  }, []);

  return (
    <div className="App">
      <Header />
      <Suspense fallback={<div style={{ fontSize: "1000px" }}>Loading...</div>}>
        <LoadingBar
          color="#06A7D3"
          progress={progress}
          height={3}
          onLoaderFinished={() => setProgress(0)}
        />

        <Switch>
          <Route exact path="/" component={Services} />
          <Route exact path="/testimonials" component={Testimonials} />
          <Route exact path="/videos" component={Videos} />
          <Route exact path="/Adminletter" component={Adminletter} />
          <Route
            exact
            path="/visitorGuidelines"
            component={VisitorGuidelines}
          />
          <Route exact path="/aboutUs" component={About} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/contactUs" component={Contact} />
          <Route exact path="/intranet" component={Intranet} />
          <Route exact path="/blog" component={Blogtopics} />
          <Route exact path="/Admindashboard" component={Admindashboard} />
          <Route exact path="/accessibility" component={Accessibility} />
          <Route exact path="/voipCall" component={Voipcall} />
          <Route exact path="/feedback" component={Feedback} />
          <Route
            exact
            path="/blog/:blogid/:blogcategory"
            component={Blogarticle}
          />
          <Route component={Nomatch} />
        </Switch>
        <Footer />
      </Suspense>
    </div>
  );
};

export default withRouter(Routes);
