import React from "react";

import { Link } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
// Global material ui theme styles
import MyTheme from "./Theme";
import Formservice from "../../services/Formservice";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Moment from "moment";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import DatePicker from "react-date-picker";
import MaskedInput from "react-text-mask";
import ReCAPTCHA from "react-google-recaptcha";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Community extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      fullNameError: "",
      email: "",
      emailError: "",
      phoneNumber: "",
      phoneNumberError: "",
      textmask: "(1  )    -    ",
      date: new Date(),
      captchaVerify: false,
    };
  }

  changeDate = (date) => {
    let formatDate = Moment(date).format("YYYY-MM-DD hh:mm:ss");

    this.setState({ date });
    console.log(this.state);
  };

  onChange = (e, string) => {
    this.setState({
      [e.target.name]: e.target.value,
      [string]: event.target.value,
    });
  };

  captchaVertify = (value) => {
    const capVerify = value;
    if (capVerify !== "") {
      this.setState({
        captchaVerify: true,
      });
    }
    //console.log(this.state);
  };

  formValidate = () => {
    let isError = false;
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const errors = {
      fullNameError: "",
      emailError: "",
      phoneNumberError: "",
      captchaError: "",
    };

    if (this.state.fullName === "") {
      isError = true;
      errors.fullNameError = "* Please enter your full name";
    }

    if (this.state.email.indexOf("@") === -1) {
      isError = true;
      errors.emailError = "* Please enter a valid email address";
    }

    if (phoneRegex.test(this.state.phoneNumber) === false) {
      isError = true;
      errors.phoneNumberError = "* Please enter a valid phone number";
    }

    if (this.state.captchaVerify === false) {
      isError = true;
      errors.captchaError = "* Please verify you're not a robot";
    }

    this.setState({
      ...this.state,
      ...errors,
      isError,
    });

    return isError;
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const err = this.formValidate();

    if (!err) {
      // clear the form
      this.setState({
        fullName: "",
        fullNameError: "",
        email: "",
        emailError: "",
        phoneNumber: "",
        textmask: "(1  )    -    ",
        phoneNumberError: "",
        date: new Date(),
      });

      const successData = {
        fullName: this.state.fullName,
        email: this.state.email,
        phone: this.state.phoneNumber,
        date: Moment(this.state.date).format("MM-DD-YYYY hh:mm a"),
      };

      const communityData = await Formservice.communityAuth(successData);

      alert(
        "Thank you. We saved your information. Welcome to Richmond Post-Acute Care community!"
      );
    }
  };

  render() {
    const { textmask } = this.state;
    return (
      <div className="communityContainer shadow-sm">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-sm-11 col-sm-offset-1">
              <MuiThemeProvider theme={MyTheme}>
                <form
                  method="POST"
                  noValidate
                  autoComplete="off"
                  onSubmit={(e) => this.onSubmit(e)}
                  id="communityForm"
                >
                  <h4>Join Our Community</h4>
                  <div className="form-row">
                    <Grid container wrap="nowrap">
                      <Grid item xs>
                        <TextField
                          style={{ padding: 0 }}
                          InputLabelProps={{ shrink: true }}
                          name="fullName"
                          value={this.state.fullName}
                          type="text"
                          label="Full Name"
                          error={this.state.fullNameError ? true : false}
                          helperText={this.state.fullNameError}
                          variant="outlined"
                          onChange={(e) => this.onChange(e)}
                        />
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          name="email"
                          type="email"
                          value={this.state.email}
                          label="Email Address"
                          error={this.state.emailError ? true : false}
                          helperText={this.state.emailError}
                          variant="outlined"
                          onChange={(e) => this.onChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="form-row">
                    <Grid container wrap="nowrap">
                      <Grid item xs={6}>
                        {/* <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Phone Number"
                          name="phoneNumber"
                          type="tel"
                          value={this.state.phoneNumber}
                          variant="outlined"
                          onChange={e => this.onChange(e)}
                        /> */}

                        <FormControl>
                          <InputLabel
                            htmlFor="formatted-text-mask-input"
                            style={
                              this.state.phoneNumberError
                                ? { color: "red" }
                                : null
                            }
                          >
                            {this.state.phoneNumberError
                              ? this.state.phoneNumberError
                              : "Phone Number"}
                          </InputLabel>
                          <Input
                            type="tel"
                            value={textmask}
                            name="phoneNumber"
                            onChange={(e) => this.onChange(e, "textmask")}
                            inputComponent={TextMaskCustom}
                            error={this.state.phoneNumberError ? true : false}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker
                          name="date"
                          onChange={this.changeDate}
                          minDate={new Date()}
                          value={this.state.date}
                        />
                      </Grid>
                    </Grid>
                    <Grid container wrap="nowrap">
                      <Grid item xs={12}>
                        <ReCAPTCHA
                          sitekey={process.env.RECAPTCHA_API_KEY}
                          onChange={this.captchaVertify}
                        />
                        {this.state.captchaVerify === false ? (
                          <p style={{ textAlign: "left", color: "red" }}>
                            {this.state.captchaError}
                          </p>
                        ) : null}
                      </Grid>
                    </Grid>
                  </div>
                  <div className="form-row">
                    <Button variant="outlined" type="submit">
                      REGISTER
                    </Button>
                    <a href="tel:5102375182">Contact us by phone</a>
                  </div>
                </form>
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Community;
