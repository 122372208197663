import React from "react";

const Brochure = () => {
  return (
    <div className="fluid-container two-column pt-3">
      <div className="twoColumn email">
        <div className="container">
          <div className="row">
            <div className="brochureContainer">
              <h2 className="p-3">View Our Brochure</h2>
              <p className="p-3">
                View Richmond Post-Acute Care exclusive brochure of the latest
                and best services we have to offer.
              </p>
              <div className="brochureButton m-3">
                <a
                  className="btn btn-primary"
                  id="uniqueButton"
                  href="https://online.fliphtml5.com/vmbic/cgoa/"
                  target="_blank"
                  title="View Richmond Post-Acute Care Brochure"
                >
                  <i className="fas fa-book"></i> VIEW BROCHURE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brochure;
