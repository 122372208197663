import base64 from "base-64";

var appController = {
  encodeData(data) {
    let encodeString = data;
    for (let i = 0; i < 10; i++) {
      encodeString = base64.encode(encodeString);
    }
    return encodeString;
  },
  decodeData(data) {
    let decodeString = data;
    for (let i = 0; i < 10; i++) {
      decodeString = base64.decode(decodeString);
    }
    return decodeString;
  },
  friendlyUrl(data) {
    let str = data;
    str = str.replace(/\s+/g, "-").toLowerCase();
    return str;
  },
  removeHyphen(data) {
    let str = data;
    str = str.split("-").join(" ");
    return str;
  },
  userData() {
    const getuserData = localStorage.getItem("userData");
    const userData = JSON.parse(getuserData);

    return userData;
  },
  converttoCelcius(temp) {
    let temperature = temp;
    let cTemp = (temperature - 32) * (5 / 9);

    return cTemp;
  },
  isAdmin() {
    let userData;
    if (localStorage.getItem("userData") !== null) {
      userData = JSON.parse(localStorage.getItem("userData"));
      if (userData.role_id === 3) {
        return userData;
      }
    }
  }
};

export default appController;
