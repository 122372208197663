import React from "react";

import Adminediting from "../common/isEditing";
import Addblogtopic from "./Addblogtopic";

import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Moment from "moment";
import dataTip from "data-tip";
import { confirmAlert } from "react-confirm-alert";
import CKEditor from "ckeditor4-react";
import AOS from "aos"; // Animate on scroll

import blogtopicsService from "../../services/Blogservice";
import appController from "../../controllers/appController";

class Blogtopics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      blogData: [],
      visible: 5,
      hoverSelected: false,
      isEditing: false,
      email: "",
      firstName: "",
      lastName: "",
      roleId: "",
      userId: "",
      errorMsg2: false,
      errorMsg3: false,
    };
  }

  userData = async () => {
    // Check if user is logged in
    if (localStorage.getItem("userData") !== null) {
      const userObject = JSON.parse(localStorage.getItem("userData"));

      await this.setState({
        email: userObject.email,
        firstName: userObject.firstName,
        lastName: userObject.lastName,
        roleId: userObject.role_id,
        userId: userObject.user_id,
      });
    }
  };

  Selectblogtopics = async () => {
    const blogTopics = await blogtopicsService.selectblogTopics();

    this.setState({
      blogData: blogTopics,
    });
    // console.log(this.state);
  };

  toggleHover = (hoverState) => {
    this.setState({ hoverSelected: hoverState });
  };

  cancelBlog = (editingState) => {
    console.log("canceling blog...", editingState);
    this.setState({
      isEditing: editingState,
    });
  };

  editBlog = (editingState) => {
    console.log("editing blog...", editingState);
    this.setState({
      isEditing: editingState,
    });
  };

  publishBlog = (editingState) => {
    console.log("publishing blog...", editingState);
    this.setState({
      isEditing: editingState,
    });
    setTimeout(async () => {
      this.setState({
        isLoading: false,
      });
      const uploadData = {
        blogData: this.state.blogData,
        modifiedDate: Moment().format("YYYY-MM-DD hh:mm:ss"),
      };
      const updateBlog = await blogtopicsService.editBlog(uploadData);
      //console.log(uploadData);
      // reload the page with new updated content
      location.href = "/blog";
      // console.log('blog got hit');
    }, 1000);
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  setBlogProperty(index, propName, propValue, blogId) {
    //console.log(propValue);
    this.setState((state) => {
      state.blogData[index][propName] = propValue;
    });
  }

  fileUpload = (index, fileName, filePayload, fileProps, blogId) => {
    var dataTypeURL = new FileReader();
    // console.log(fileProps);

    dataTypeURL.onload = (filePayload) => {
      this.setState((state) => {
        state.blogData[index]["fileName"] = fileProps[0].name;
        state.blogData[index]["fileSize"] = fileProps[0].size;
        state.blogData[index]["fileType"] = fileProps[0].type;
        state.blogData[index]["filePayload"] = filePayload.target.result;
      });
    };

    //console.log(fileData);

    dataTypeURL.readAsDataURL(fileProps[0]);
  };

  deleteBlog = (blogId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure</h1>
            <p>You want to delete this blog?</p>
            <button onClick={onClose}>Cancel</button>
            <button
              onClick={() => {
                this.confirmDelete(blogId);
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  };

  confirmDelete = async (blogId) => {
    // Delete the blog
    const deleteBlog = await blogtopicsService.deleteBlog({ blog_id: blogId });

    // Re-render the blog posts after deleting
    await this.Selectblogtopics();
  };

  // Used for loading more posts if user requests it.
  handleShowMorePosts = () => {
    this.setState((prev) => {
      return { visible: prev.visible + 4 };
    });
  };

  async componentDidMount() {
    AOS.init();
    await this.userData();
    await this.Selectblogtopics();

    CKEditor.editorUrl = "/assets/js/ckeditor/ckeditor.js";
  }

  render() {
    return (
      <div className="fluid-container">
        <div className="blogContainer">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Richmond Post-Acute Care Blogs</title>
            <meta name="description" content="Richmond Post-Acute Care Blogs" />
            <link
              rel="canonical"
              href="https://www.richmondpostacute.com/Blog"
            />
          </Helmet>
          {this.state.roleId === 1 ? (
            <Adminediting
              title={this.props.match.path}
              cancelBlog={this.cancelBlog}
              editBlog={this.editBlog}
              publishBlog={this.publishBlog}
            />
          ) : null}
          <div className="container">
            {this.state.roleId === 1 ? (
              <div className="editSection">
                <div className="text-right">
                  <span className="data-tip-bottom" data-tip="Add Blog Post">
                    <i className="fas fa-plus" onClick={this.handleShow} />
                  </span>
                </div>
              </div>
            ) : null}
            <div className="blogContent">
              {this.state.blogData
                .slice(0, this.state.visible)
                .map((rows, index) => (
                  <div className="blogWrapper p-1 my-3 shadow-sm" key={index}>
                    {rows.blog_status === 1 ? (
                      <div className="row" data-aos="fade-down">
                        <div className="col-md-4">
                          <img
                            src={
                              "https://richmondpostacutecarebucket.s3-us-west-1.amazonaws.com/richmondstaging/" +
                              rows.blog_thumbnail
                            }
                          />
                          {this.state.isEditing === true ? (
                            <div className="input-group">
                              {/* Wrong file type. */}
                              {this.state.errorMsg2 === true ? (
                                <p style={{ color: "red" }}>
                                  Please upload only JPG or PNG
                                </p>
                              ) : null}
                              {/* File is too big. */}
                              {this.state.errorMsg3 === true ? (
                                <p style={{ color: "red" }}>
                                  Please upload a file less than 3MB
                                </p>
                              ) : null}
                              <input
                                type="file"
                                className="d-block mt-4"
                                name="blogPicture"
                                onChange={(e) =>
                                  this.fileUpload(
                                    index,
                                    "blog_picture",
                                    "blog_payload",
                                    e.target.files,
                                    rows.blog_id
                                  )
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6">
                          {this.state.isEditing === true ? (
                            <input
                              type="text"
                              name="blogCategory"
                              onChange={(e) =>
                                this.setBlogProperty(
                                  index,
                                  "blog_category",
                                  e.target.value,
                                  rows.blog_id
                                )
                              }
                              defaultValue={rows.blog_category}
                            />
                          ) : (
                            <Link
                              to={
                                "/blog/" +
                                rows.blog_id +
                                "/" +
                                appController.friendlyUrl(rows.blog_category)
                              }
                              id="blogUrl"
                            >
                              <h3
                                id="blogTopic"
                                className="pt-3"
                                dangerouslySetInnerHTML={{
                                  __html: rows.blog_category,
                                }}
                              />
                            </Link>
                          )}
                          {this.state.roleId === 1 ? (
                            <div className="editSection">
                              <div className="text-right">
                                <span
                                  className="data-tip-bottom"
                                  data-tip="Delete Blog Post"
                                >
                                  <i
                                    className="far fa-trash-alt"
                                    onClick={(e) =>
                                      this.deleteBlog(rows.blog_id)
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          ) : null}

                          <div
                            className={
                              this.state.hoverSelected == index
                                ? "blogSection hover"
                                : "blogSection"
                            }
                          >
                            {this.state.isEditing === true ? (
                              <CKEditor
                                data={rows.blog_content}
                                onChange={(event, editor) => {
                                  const data = event.editor.getData();
                                  this.setBlogProperty(
                                    index,
                                    "blog_content",
                                    data,
                                    rows.blog_id
                                  );
                                }}
                              />
                            ) : rows.blog_content.length > 50 ? (
                              <div
                                className="cmsStyles"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    rows.blog_content.substr(0, 50) + " ...",
                                }}
                              />
                            ) : (
                              <div
                                className="cmsStyles"
                                dangerouslySetInnerHTML={{
                                  __html: rows.blog_content,
                                }}
                              />
                            )}
                          </div>
                          <div className="blogInfo">
                            {/* <span className="blogAuthor" id="blogAuthor">
                            {rows.blog_author}
                          </span> */}
                            <span className="blogDate">
                              {Moment(rows.created_date).format(
                                "MMM Do YYYY, h:mm a"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
              <div className="loadMore mt-3 text-center">
                <button
                  className="btn btn-secondary btn-lg"
                  onClick={() => this.handleShowMorePosts()}
                >
                  Load More
                </button>
              </div>

              <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Blog Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Addblogtopic
                    handleClose={this.handleClose}
                    selectblogTopics={this.Selectblogtopics}
                  />
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Blogtopics;
